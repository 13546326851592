import React, { useEffect } from "react";
import { useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import { Link } from "react-scroll";
import $ from "jquery";
import Sidebar from "../components/sidebar.js";
import { useNavigate } from 'react-router-dom';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  // Countdown Timer
  const currentDate = new Date();
  const history = useNavigate();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span>
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">Days</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">Hours</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">Minutes</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label">Seconds</span>
        </span>
      </div>
    );
  };
  const [state, setState] = useState(false)
  const Click = () => {
      setState(!state)
  }
  // $(".primary_btn").(function(){
  //   $(".sidebar").addClass("menu_show");
  // });
  $(document).ready(function(){
    $("button").click(function(){
      $(".sidebar").addClass("menu_show");
    });
  });

  async function movebuy(){
    history("/buytoken")
  }

  async function moveStake(){
    history("/Staking")
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      
      <Sidebar />

      <div className="page_header" name="home">
        <div className="banner">
          <div className="container">


            {/* <div className="row" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000" id="trigger-about"> */}
            <div className="row">
            <div className="col-md-9 col-lg-9 m-auto">
              <div className="presale_box">
                <div className="presale_box_inner"></div>
                <h2>Presale Ends In</h2>
                <p>
                  At vero eos et accusamus et iusto odio ignissimos ducimus qui
                  blanditiis praesentium um deleniti atque corrupti.
                </p>
                <Countdown date={`2023-03-10T20:00:59`} renderer={renderer} />
                <div className="time_blw">
                  {" "}
                  <p>Soft cap:</p> <span>2500 BNB </span>{" "}
                  <div className="line">|</div> <p>Hard cap:</p>{" "}
                  <span>5400 BNB </span>
                </div>
                <button className="primary_btn mt-3" type="button" onClick={()=>movebuy()}>JOIN & BUY TOKEN NOW</button>
                <div className="presale_box_outer"></div>
              </div>
            </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="features" name="features">
          <div className="container">
            <h2 className="main_title text-center">Our Great Features</h2>
            <div className="row">
              <div className="col-md-6">
                <div className="box">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/platinum/f1.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </div>
                  <h5 className="h5tag">
                    Auto Liquidity and <span>LP Tokens Burn</span>
                  </h5>
                  <p>
                    60% of BNB received from presale will be automatically goes
                    to liquidity and all LP tokens will be burned.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/platinum/f2.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </div>
                  <h5 className="h5tag">
                    Limited Supply with Highest <span> Burning Rate</span>
                  </h5>
                  <p>
                    Unlike other tokens which are inflationary and have
                    unlimited supply, Platinum Swap has a limited supply of 1
                    trillion tokens, of which 20% has been burned at launch.
                    Even more, for every sell 20% of the tokens are burned - the
                    highest burning percentage in the whole market - make
                    Platinum Swap a hyper deflationary token never seen before.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/platinum/f3.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </div>
                  <h5 className="h5tag">
                    Low <span> TransactionFee</span>
                  </h5>
                  <p>
                    Platinum Swap has a low 4% Transaction fee compare with
                    13-16% buy fee of other transfer tax token 1% of transaction
                    volume will be automatically rewarded to the biggest buyer
                    during that time. You can now get high income from PLATINUM
                    SWAP - the world's first hyper-deflationary token designed
                    to deliver what any holders desire most: scarcity,
                    sustainability, and price appreciation over time - easily by
                    take advantage of the low fee & the highest buyer rewards
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/platinum/f4.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </div>
                  <h5 className="h5tag">
                    Earn 2190% APY Your{" "}
                    <span> PlatinumSwap-BNB LP Staking</span>
                  </h5>
                  <p>
                    With a market capitalization of $39 billion, BNB is the 5th
                    biggest coin on the market. The coin has already made many
                    millionaires. Its rapid adoption will probably make many
                    more millionaires in the coming years. BNB is a great
                    investment option for investors looking for a long term
                    asset because of the strong use case of BNB on the world #1
                    Binance Exchange with 15 billions volume everyday, and the
                    solid growth of BNB Chain network, the #2 chain just behind
                    Ethereum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="stacking" name="stacking">
          <div className="container">
            <div className="stack_box">
              <div className="row">
                <div className="col-md-8 m-auto">
                  <div>
                    <h2 className="main_title">Staking</h2>
                    <p>
                      Stake PTSWAP-BNB LP coins to earn PLATINUM SWAP at high
                      APY.
                    </p>
                    <button className="primary_btn" onClick={()=>moveStake()}>STAKE</button>
                  </div>
                </div>
                <div className="col-md-4 m-auto">
                  <div>
                    <img
                      src={require("../assets/images/platinum/stack_img.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="token" name="token">
          <div className="container">
            <div className="head">
              <h2 className="main_title">Token Sale Info</h2>
              <p className="mb-0">
                Mauris tincidunt sapien at ligula malesuada, nec pellentesque
                lectus ornare. Pellentesque elementum blandit aliquam. Nam eu
                venenatis justo, consectetur congue lacus.{" "}
              </p>
            </div>
            <div className="row">
              <div className="col-md-6 m-auto">
                <div>
                  <img
                    src={require("../assets/images/platinum/token.png")}
                    className="img-fluid"
                    alt="Icon"
                  />
                </div>
              </div>
              <div className="col-md-6 m-auto">
                <div className="box">
                  <h4>Tokenomics</h4>
                  <p className="mb-3" >Total Supply: 10000000000 tokens ( 10 billion )</p>
                  <ul>
                    <li>
                      <p>Presale</p>
                      <div>60%</div>
                    </li>
                    <li>
                      <p>Airdrop</p>
                      <div>10%</div>
                    </li>
                    <li>
                      <p>Pools Liquidity</p>
                      <div>20%</div>
                    </li>
                    <li>
                      <p>Initial Burn</p>
                      <div>10%</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="referral" name="referral">
          <div className="container">
            <div className="referral_box">
              <div className="up_box">
                <h3>Referral Rewards</h3>
                <div className="row">
                  <div className="col-md-3">
                    <div className="box">
                      <p>Level 1 Commission</p>
                      <h5>5%</h5>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="box">
                      <p>Level 2 Commission</p>
                      <h5>3%</h5>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="box">
                      <p>Level 3 Commission</p>
                      <h5>2%</h5>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="box">
                      <p>Bonus on Purchase</p>
                      <h5>10%</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blw_box">
                <p>
                  30 days presale | After 30 days all unsold tokens will be
                  burnt | 60% of Presale BNB raised will be added to LP and LP
                  tokens will be burnt.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="roadmap" name="roadmap">
          <div className="container">
          <div className="text-center">
            <h2 className="main_title mb-3">Roadmap</h2>
            <p className="mb-5">Q1-2023</p>
            <img
                    src={require("../assets/images/platinum/roadmap_img.png")}
                    className="img-fluid"
                    alt="Icon"
                  />
          </div>
          </div>
        </section>
     <div  className="faq">
      <section className="faq_section" name="faq">
          <div className="faq_wrap">
            <div className="container">
            <h2 className="main_title text-center" >FAQ</h2>
              <div className="row">
                <div
                  className="col-md-12 col-lg-10 m-auto"
                  // data-aos="fade-up"
                  // data-aos-anchor="#trigger-faq"
                  // data-aos-anchor-placement="top-top"
                  // data-aos-duration="1000"
                  // data-aos-delay="600"
                >
                  <div className="accordion" id="faq_accodian">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <span
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                     What is a presale?
                        </span>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>A presale is an initial sale of tokens or coins. It allows investors to purchase tokens at a discounted price before the general public has the opportunity to buy them.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <span
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                     How to Buy PLATINUM SWAP IN Presale?
                        </span>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p> <span>A crypto wallet.</span> </p>
                          <p>Please install MetaMask or Trust Wallet on your phone. Make sure you have download the APP from official website.</p>
                          <p> <span>Some BNB.</span></p>
                          <p>Buy some BNB from Binance, send it to your crypto wallet. The minimum purchase amount is 0.05 BNB, but you need a little gas fee in your wallet, so make sure you have at least 0.06 BNB in your wallet.</p>
                          <div className="ms-4">
                            <p><div className="step">Step1.</div> <span> Open the link</span></p>
                           <p>Open the PRESALE link at your browser, we recommend using the DAPP browser  </p>
                            <p><div className="step">Step2.</div> <span> Connect your wallet</span></p>
                            <p>Tap the Connect button at the upper-right corner to connect your wallet.</p>
                            <p><div className="step">Step3.</div> <span> Buy PLATINUMSWAP</span></p>
                            <p>Enter the purchase amount, Click "Get PLATINUM SWAP" button, confirm it at your wallet,</p>
                            <p><div className="step">Step4.</div> <span>View History</span></p>
                            <p>The purchase history will show after a few minutes. Now you have successfully purchased PLATINUM SWAP token</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <span
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                      How to Stake PLATINUMSWAP-BNB LP
                        </span>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                           <p><div className="step">Step1.</div> <span> Open the link</span></p>
                           <p>Visit the link with your browser: https://platinumswap.finance</p>
                            <p><div className="step">Step2.</div> <span> Connect  wallet</span></p>
                            <p>Tap the Connect button at the upper-right corner to connect your wallet.</p>
                            <p><div className="step">Step3.</div> <span> ADD LIQUIDITY</span></p>
                            <p>Add liquidity for PLATINUMSWAP-BNB pair and get Platinum swap-BNB LP Tokens</p>
                            <p><div className="step">Step4.</div> <span>Stake </span></p>
                            <p>Enter the amount, click the "Stake" button and approve it. </p>
                      
                        </div>
                      </div>
                    </div>

                    {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          When will the presale end and when will tokens be
                          distributed?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                            The presale end date and token distribution date
                            should be provided on the presale website. These
                            dates can be subject to change based on the progress
                            of the project.
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      </div>
       <div className="curve"></div>

     <Footer />








      </div>
    </div>
  );
}
