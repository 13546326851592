import React  from "react";
import $ from "jquery";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
export default function Sidebarinner() {

    $(document).ready(function(){
        $(".menu_icon").click(function(){
          $(".sidebar").addClass("menu_show");
        });
      });
      $(document).ready(function(){
        $(".close_icon").click(function(){
          $(".sidebar").removeClass("menu_show");
        });
      });

  return (
    <>
    <div className="sidebar">
         <div className="close_icon">
        <i class="fas fa-times"></i>
        </div>
      <div className="sidebar_logo">
       <NavLink to="/" >
      <img
      src={require("../assets/images/platinum/logo.png")}
      className="img-fluid"
        alt="Icon"
        />
        </NavLink>
        </div>
       <ul>
        <li><NavLink  to="/"  spy={true} smooth={true} duration={250} color="transparent" className="nav-link text-center p-3" >Go to Home</NavLink></li>
        <li><NavLink  to="/buytoken"  spy={true} smooth={true} duration={250} color="transparent" className="nav-link text-center p-3" >Buy</NavLink></li>
        <li><NavLink  to="/Staking"  spy={true} smooth={true} duration={250} color="transparent" className="nav-link text-center p-3" >Stake</NavLink></li>
       </ul>
      <button className="primary_btn">CONNECT WALLET</button>
      <div class="dropdown">
                <button class="primary_btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                  Dropdown link
                </button>
              
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li><p>balance:</p> <p class="dropdown-item" >0.000</p></li>
                <li><a class="dropdown-item" href="/Staking">Stake</a></li>
                  <li><a class="dropdown-item" href="/">Log out</a></li>
                </ul>
              </div>
      </div>
    </>
  );
}
