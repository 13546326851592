import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Link } from "react-router-dom";
import Sidebar from "../components/sidebar.js";
import Sidebarinner from "../components/sidebarinner.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Refferal(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />

      <Sidebarinner />

      <div className="buytoken referral">
        <div className="buytokenbg">
          <div className="container">
            <div className="head">
              <h2 className="main_title">Referral Rewards</h2>
              <p>
                Participate in PLATINUMSWAP staking, earn PLATINUMSWAP while you
                sleep
              </p>
            </div>
            <div className="head mb-2">
              <p className="invite_addr">Invite Address (BNB)</p>
              <p className="addr">
                0xFdDbcC2C3f04147C3F5eF94E6D83aA164EBA2739{" "}
                <i class="far fa-copy"></i>
              </p>
            </div>
            <div className="row">
              <div className="col-xl-9 col-xxl-8 mx-auto">
                <div className="box">
                  <div className="box_ctn">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="refer_box">
                          <p>Referral Purchases</p>
                          <h5>0 BNB</h5>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="refer_box">
                          <p>Total Referrals</p>
                          <h5>0</h5>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="refer_box">
                          <p>Claimed</p>
                          <h5>0 BNB</h5>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="refer_box">
                          <p>Commission</p>
                          <h5>0 BNB</h5>
                        </div>
                      </div>
                    </div>
                      <button class="primary_btn">CLAIM</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="curve"></div>
      <Footer />
    </div>
  );
}
