import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import Web3 from "web3";
import WalletConnectProvider from '@walletconnect/web3-provider';
import config from "../config/config";
import { getConnect } from "../action/apicontroller";
import { useNavigate } from 'react-router-dom';
import { toastAlert } from "../helper/toastAlert";

export default function WalletModal(props) {
    const history = useNavigate();
    const timerRef = useRef(null);
    useEffect(() => {
        getdetails();
    }, []);



    async function getdetails() {
        let prov_details = await getConnect();
        if (prov_details && prov_details) {
            let web3 = new Web3(prov_details.provider ? prov_details.provider : window.ethereum)
            var network = await web3.eth.net.getId();
            var result = await web3.eth.getAccounts();
            if (network == config.networkVersion && result && result[0]) {
                localStorage.setItem("account", result[0]);
            } else {
                localStorage.setItem("account", "");
                history("/")
            }
        } else {
            // localStorage.setItem("account", "");
            // history("/")
        }
    }

    async function connectTrustWallet() {
        try {
            var web3 = new Web3(window.ethereum);
            // const provider = new WalletConnectProvider({
            //   rpc: {
            //     56: "https://bsc-dataseed.binance.org/",
            //   },
            //   chainId: 56,
            // });
            const provider = new WalletConnectProvider({
                rpc: {
                    97: "https://data-seed-prebsc-1-s3.binance.org:8545/",
                },
                chainId: 97
            });

            provider.on("connect", () => { });
            await provider.enable();
            web3 = new Web3(provider);
            var network = await web3.eth.net.getId();
            var result = await web3.eth.getAccounts();
            if (result[0] != undefined) {
                if (config.networkVersion == network) {
                    localStorage.setItem("account", result[0]);
                    localStorage.setItem("wallettype", "trust");
                    if (window.location.href == "/Staking") {
                        window.location.href = "/Staking";
                    } else {
                        window.location.href = "/buytoken";
                    }
                } else {
                    await provider.disconnect();
                    toastAlert("error", "please select BSC chain network", "network");
                }
            }
        } catch (err) {
            toastAlert("error", "Error Occured Please Try again", "network");
            localStorage.clear();
        }
    }

    async function connectClick() {
        try {
            if (window.ethereum) {
                await window.ethereum.enable();
                const web3 = new Web3(window.ethereum);
                const result = await web3.eth.getAccounts();
                web3.eth.net.getId().then(async (res) => {
                    if (result[0] != undefined) {
                        if (res === config.networkVersion) {
                            toastAlert("success", "Connected Wallet", "excerciseerr");
                            localStorage.setItem("account", result[0]);
                            localStorage.setItem("wallettype", "metamask");
                            console.log(window.location.pathname, 'window.location.href')
                            if (window.location.pathname == "/Staking") {
                                window.location.href = "/Staking";
                            } else {
                                window.location.href = "/buytoken";
                            }

                        } else {
                            toastAlert("error", "Connect BSC Network", "excerciseerr");
                            return false;
                        }
                    }
                });
            }
        } catch (err) {
            console.log(err, "=====errrr")
        }
    }
    window.addEventListener('load', async (event) => {
        if (localStorage.getItem("wallettype") && localStorage.getItem("wallettype") == "metamask") {
            event.preventDefault();
            var provider = window.ethereum;
            var web3 = new Web3(provider);
            if (window.ethereum) {
                window.ethereum.on('accountsChanged', async function (accounts) {
                    if (timerRef.current) {
                        clearTimeout(timerRef.current);
                    }
                    timerRef.current = setTimeout(async () => {
                        var result = await web3.eth.getAccounts()
                        var setacc = result[0];
                        localStorage.setItem("account", result[0])
                        localStorage.setItem('wallettype', "metamask");
                        window.location.href = "/";
                    }, 1000);
                })

                window.ethereum.on('networkChanged', async function (networkId) {
                    var provider = window.ethereum;
                    var web3 = new Web3(provider);
                    if (networkId == config.networkversion) {
                        if (timerRef.current) {
                            clearTimeout(timerRef.current);
                        }
                        timerRef.current = setTimeout(async () => {
                            var result = await web3.eth.getAccounts()
                            var setacc = result[0];
                            localStorage.setItem("account", result[0])
                            localStorage.setItem('wallettype', "metamask");
                            window.location.href = "/";
                        }, 1000);
                    }
                    else {
                        localStorage.clear()
                        window.location.href = "/";
                    }
                })
            }
        }
    })

    async function logout() {
        localStorage.clear()
        window.location.href = "/";
    }

    return (
        <>
            {/* Connect Wallet Modal */}
            <div
                className="modal fade primary_modal"
                id="connect_wallet_modal"
                tabIndex={-1}
                aria-labelledby="connect_walletModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connect_walletModalLabel">
                                Connect Wallet
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="m-2 mb-3">
                                    <a target="_blank" className="mb-3 d-block">
                                        <button type="button" className="connect_wallet_button" onClick={() => connectClick()}>
                                            <img
                                                src={require("../assets/images/metamask.png")}
                                                height="28"
                                                width="28"
                                                className="img-fluid"
                                            />{" "}
                                            <span>MetaMask</span>
                                        </button>
                                    </a>
                                    <a target="_blank" className="mb-3 d-block">
                                        <button type="button" className="connect_wallet_button" onClick={() => connectTrustWallet()}>
                                            <img
                                                src={require("../assets/images/trustwallet.png")}
                                                height="28"
                                                width="28"
                                                className="img-fluid"
                                            />{" "}
                                            <span>Trust Wallet</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
