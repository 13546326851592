import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Home from './pages/home.js';
// import BuyToken from './pages/buy-token';
import Buy_Token from './pages/buytoken';
import Staking from "./pages/staking.js";
import Refferal from "./pages/referral.js";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

const App = () => {
  const history = useNavigate();

  const routes = useRoutes([
    { path: "/", element: <Home />, history: { history } },
    // { path: "buy-token", element: <BuyToken /> } ,
    { path: "buytoken", element: <Buy_Token />, history: { history } },
    { path: "Staking", element: <Staking />, history: { history } },
    { path: "refferal", element: <Refferal />, history: { history } }
  ]);
  return routes;
};

AOS.init();

const AppWrapper = () => {

  return (
    <Router basename="/" >
      <ToastContainer />
      <App />
    </Router>
  );
};

export default AppWrapper;