let NODE_ENV = 'production';

let Front_URL = '';
let Server_URL = "";
let networkVersion = "";
let persaleContract = "";
let tokenContract = "";
let lpcontract = "";
let stakeContract = "";
let networkurl = "";

let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}

if (NODE_ENV === "production") {
    Front_URL = 'https://platinumswapfinance-swap.pages.dev/';
    Server_URL = '';
    networkVersion = 97;
    networkurl = "https://testnet.bscscan.com/";
    persaleContract = "0x7dDBC87D21b6fdB7964fbc9B4D170B305cBbe21e";
    stakeContract = "0x13c3Ee60F64A7FBa74930bbF484C81c3ef3e9ce1";
    tokenContract = "0xa2a127791290031D835B6a16190084672c2420eD";
    lpcontract = "0x9bbf24bfd6b082d018a928a706d2df2a21d651c0";
}
else if (NODE_ENV === "demo") {
    Front_URL = 'https://platinumswapfinance-swap.pages.dev/';
    Server_URL = '';
    networkVersion = 97;
    networkurl = "https://testnet.bscscan.com/";
    persaleContract = "0x7dDBC87D21b6fdB7964fbc9B4D170B305cBbe21e";
    stakeContract = "0x13c3Ee60F64A7FBa74930bbF484C81c3ef3e9ce1";
    tokenContract = "0xa2a127791290031D835B6a16190084672c2420eD";
    lpcontract = "0x9bbf24bfd6b082d018a928a706d2df2a21d651c0";
}
else {
    Front_URL = 'http://localhost:3000/';
    Server_URL = 'http://localhost:2053/';
    networkVersion = 97;
    networkurl = "https://testnet.bscscan.com/";
    persaleContract = "0x7dDBC87D21b6fdB7964fbc9B4D170B305cBbe21e";
    stakeContract = "0x13c3Ee60F64A7FBa74930bbF484C81c3ef3e9ce1";
    tokenContract = "0xa2a127791290031D835B6a16190084672c2420eD";
    lpcontract = "0x9bbf24bfd6b082d018a928a706d2df2a21d651c0";
}

let key = {
    Front_URL: Front_URL,
    Server_URL: Server_URL,
    networkVersion: networkVersion,
    "persaleContract": persaleContract,
    "stakeContract": stakeContract,
    "tokenContract": tokenContract,
    "lpContract": lpcontract,
    "networkurl": networkurl
};

export default key;
