import axios from 'axios';
import config from '../config/config';
import Web3 from "web3";
import WalletConnectProvider from '@walletconnect/web3-provider';

export const getConnect = async (payload) => {
  if(localStorage.getItem("wallettype") && localStorage.getItem("wallettype")!=null && localStorage.getItem("wallettype")!=undefined && localStorage.getItem("wallettype")!="" && localStorage.getItem("wallettype")=="trust"){
    try{
      var web3 = new Web3(window.ethereum);
      // const provider = new WalletConnectProvider({
      //   rpc: {
      //     56: "https://bsc-dataseed.binance.org/",
      //   },
      //   chainId: 56
      // });
      const provider = new WalletConnectProvider({
        rpc: {
          97: "https://data-seed-prebsc-1-s3.binance.org:8545/",
        },
        chainId: 97
      });

      provider.on("connect", () => {
      });
      await provider.enable();
      localStorage.setItem("wallettype","trust")
      var data = {
         provider : provider,
      }
      return data
    }catch(err){
      localStorage.clear();
      window.location.reload()
    }
  }
  else if(localStorage.getItem("wallettype") && localStorage.getItem("wallettype")!=null && localStorage.getItem("wallettype")!=undefined && localStorage.getItem("wallettype")!="" && localStorage.getItem("wallettype")=="metamask"){
   if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          let acc =  await window.ethereum.request({ method: 'eth_requestAccounts' });
         // await window.ethereum.enable();
          localStorage.setItem("wallettype","metamask")
          var data = {
            "connect" : "true"
          }
          return data
        }
      }catch(err){
        console.log("inapi",err)
        localStorage.clear();
     }
    }
  }else{
    localStorage.clear();
  }
}

export const subscribe_now = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/subscribe`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err)
  }
}
