import React from "react";

export default function Footer(props) {

  // const [email, setemail] = useState("");

  // const mailchange = (e) => {
  //   setemail(e.target.value)
  // }

  // async function subscribe(){
  //   if(email && email!=null && email!=undefined && email!=""){
  //      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //       if(!email || regex.test(email) === false){
  //           toastAlert('error', "Enter Valid Email Id", 'network');
  //       }else{
  //         let data = {
  //           "email": email
  //         }
  //         let rest = await subscribe_now(data)
  //         if(rest && rest.data && rest.data.message){
  //           toastAlert('success',rest.data.message, 'network');
  //           setemail("");
  //         }
  //       }
  //   }else{
  //     toastAlert('error', "Enter Valid Email Id", 'network');
  //   }
  // }

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_top">
           {/* <h2>Subscribe to Our Newsletter</h2>
            <p>Sign up for a newsletter and get unlimited access to latest trends in the Platinum Swap
</p>
            <div class="input-group">
              <input
                type="email"
                class="form-control"
                aria-label="Email Address"
                aria-describedby="email"
              />
              <button type="button" class="input-group-text primary_btn" ><span className="">SUBSCRIBE</span></button>
            </div> */}   
             
            <ul className="footer_social_links">
              
              <li>
                <a href="/" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
             
              <li>
                <a href="/" target="_blank">
                  <i className="fab fa-telegram-plane"></i>
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                <i class="fab fa-whatsapp"></i>
                </a>
              </li>
              
            </ul>
          </div>
          <div className="footer_panel_bottom">
            <ul className="footer_list_links">
              <li><a href="#">Privacy Policy</a></li>   
              <li><a href="#"> Terms of sales</a></li>           
            </ul>
            <p>Copyright © {new Date().getFullYear()} <span>Platinum Swap</span>, All rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
