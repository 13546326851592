import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Link } from "react-router-dom";
import Sidebar from "../components/sidebarinner.js";
import config from "../config/config";
import { getConnect } from "../action/apicontroller";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";
import tokenabi from "../ABI/tokenabi";
import stakeabi from "../ABI/stakeabi";
import lpabi from "../ABI/lpabi";
import { toastAlert } from "../helper/toastAlert";

import WalletModal from "../components/walletModal";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Staking(props) {
  const history = useNavigate();
  const [progress1, setprogress1] = useState(false);
  const [progress2, setprogress2] = useState(false);
  const [bnbbalance, setbnbbalance] = useState(0);
  const [lpallowance, setlpallowance] = useState(0);
  const [tokenallowance, settokenallowance] = useState(0);
  const [lpuser, setlpuser] = useState({});
  const [tokenuser, settokenuser] = useState({});
  const [userlpbalance, setuserlpbalance] = useState(0);
  const [usertokenbalance, setusertokenbalance] = useState(0);
  const [adminbalance, setadminbalance] = useState(0);
  const [stakeamt, setstakeamt] = useState(0);
  const [withdrawamt, setwithdrawamt] = useState(0);
  const [lpreward, setlpreward] = useState(0);
  const [tokenreward, settokenreward] = useState(0);
  const [tokenclaim, settokenclaim] = useState(false);
  const [lpclaim, setlpclaim] = useState(false);
  const [option, setoption] = useState("stake");
  const [pid, setpid] = useState("0");
  const [progress, setprogress] = useState(false);
  const [lpinfo, setlpinfo] = useState({});
  const [tokeninfo, settokeninfo] = useState({});
  const [youwillpay, setyouwillpay] = useState(0);
  const [hash, sethash] = useState("");
  const [totalstake, settotalstake] = useState(0);
  const [liquitity, setliquitity] = useState(0);
  const [poolfee, setpoolfee] = useState(0);
  const [poolapy, setpoolapy] = useState(0);
  const [tokenfee, settokenfee] = useState(0);
  const [tokenapy, settokenapy] = useState(0);

  const [totalsupply, settotalsupply] = useState(0);
  const [totalsupply1, settotalsupply1] = useState(0);

  useEffect(() => {
    getdetails();
    getliquidity();
    const interval = setInterval(async () => {
      if (localStorage.getItem("account") && localStorage.getItem("account") != null && localStorage.getItem("account") != undefined && localStorage.getItem("account") != "") {
        getharvest();
      }
    }, 3000);
    return () => clearInterval(interval);

  }, []);

  async function getdetails() {
    let prov_details = await getConnect();
    if (prov_details) {
      let web3 = new Web3(
        prov_details.provider ? prov_details.provider : window.ethereum
      );
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (network == config.networkVersion && result && result[0]) {
        localStorage.setItem("account", result[0]);
        let Balance = await web3.eth.getBalance(result[0]);
        Balance = Balance / 1e18;
        setbnbbalance(Balance);
        const lpContract = new web3.eth.Contract(lpabi, config.lpContract);
        const stakeContract = new web3.eth.Contract(stakeabi, config.stakeContract);
        const tokenContract = new web3.eth.Contract(tokenabi, config.tokenContract);
        let _tokenuser = await stakeContract.methods.userInfo(1, result[0]).call();
        settokenuser(_tokenuser);
        let _lpuser = await stakeContract.methods.userInfo(0, result[0]).call();
        setlpuser(_lpuser);
        let _lpallowance = await lpContract.methods.allowance(result[0], config.stakeContract).call();
        setlpallowance(_lpallowance);
        let _tokenallowance = await tokenContract.methods.allowance(result[0], config.stakeContract).call();
        settokenallowance(_tokenallowance);
        let _lpreward = await stakeContract.methods.pendingToken(0, result[0]).call();;
        let _tokenreward = await stakeContract.methods.pendingToken(1, result[0]).call();

        _lpreward = (_lpreward > 0) ? (_lpreward / 10 ** 18).toFixed(6) : 0;
        _tokenreward = (_tokenreward > 0) ? (_tokenreward / 10 ** 18).toFixed(6) : 0;

        setlpreward(_lpreward);
        settokenreward(_tokenreward);
        let lpbalance = await lpContract.methods.balanceOf(result[0]).call();
        let tokenbalance = await tokenContract.methods.balanceOf(result[0]).call();
        let _adminbalance = await tokenContract.methods.balanceOf(config.stakeContract).call();
        let _lpinfo = await stakeContract.methods.poolInfo(0).call();
        let _tokeninfo = await stakeContract.methods.poolInfo(1).call();

        var fee = (_lpinfo && _lpinfo.depositFeeBP) ? parseFloat(_lpinfo.depositFeeBP) / 100 : 0;
        var apy = (_lpinfo && _lpinfo.apy) ? parseFloat(_lpinfo.apy) / 100 : 0;

        var fee1 = (_tokeninfo && _tokeninfo.depositFeeBP) ? parseFloat(_tokeninfo.depositFeeBP) / 100 : 0;
        var apy1 = (_tokeninfo && _tokeninfo.apy) ? parseFloat(_tokeninfo.apy) / 100 : 0;

        setpoolfee(fee);
        setpoolapy(apy);

        settokenfee(fee1);
        settokenapy(apy1);

        setlpinfo(_lpinfo);
        settokeninfo(_tokeninfo);
        console.log(lpbalance, 'lpbalance-lpbalance-lpbalance', tokenbalance)
        setuserlpbalance(lpbalance);
        setusertokenbalance(tokenbalance);
        setadminbalance(_adminbalance);

      } else {
        setbnbbalance(0);
        setlpallowance(0);
        settokenallowance(0);
        setlpreward(0);
        settokenreward(0);
        localStorage.setItem("account", "");
        history("/Staking");
      }
    } else {
    }
  }

  async function connect_wallet() {
    let prov_details = await getConnect();
    if (prov_details) {
      let web3 = new Web3(
        prov_details.provider ? prov_details.provider : window.ethereum
      );
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (network == config.networkVersion && result && result[0]) {
        localStorage.setItem("account", result[0]);
        history("/Staking");
      } else {
        localStorage.setItem("account", "");
        history("/Staking");
      }
    }
  }

  async function getharvest() {
    let prov_details = await getConnect();
    if (prov_details) {
      let web3 = new Web3(
        prov_details.provider ? prov_details.provider : window.ethereum
      );
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (network == config.networkVersion && result && result[0]) {
        const stakeContract = new web3.eth.Contract(stakeabi, config.stakeContract);
        var _lpreward = await stakeContract.methods.pendingToken(0, result[0]).call();;
        var _tokenreward = await stakeContract.methods.pendingToken(1, result[0]).call();
        let totaldep = await stakeContract.methods.depositedToken().call();

        _lpreward = (_lpreward > 0) ? (_lpreward / 10 ** 18).toFixed(6) : 0;
        _tokenreward = (_tokenreward > 0) ? (_tokenreward / 10 ** 18).toFixed(6) : 0;

        setlpreward(_lpreward);
        settokenreward(_tokenreward);
        settotalstake(totaldep);
      } else {
        localStorage.setItem("account", "");
        history("/Staking");
      }
    } else {
    }
  }

  async function tokenapprove(_pid) {
    try {
      _pid == 1 ? setprogress1(true) : setprogress2(true);
      let prov_details = await getConnect();
      if (prov_details) {
        let web3 = new Web3(
          prov_details.provider ? prov_details.provider : window.ethereum
        );
        var network = await web3.eth.net.getId();
        var result = await web3.eth.getAccounts();
        let amt = convert(100000000000000000000000000);
        if (network == config.networkVersion && result && result[0]) {
          if (_pid == 0) {
            //lp
            const lpContract = new web3.eth.Contract(lpabi, config.lpContract);
            var allowanceVal = await lpContract.methods
              .approve(config.stakeContract, amt.toString())
              .send({ from: result[0] });
            console.log(allowanceVal, 'allowanceValallowanceVal')
            allowanceVal = (allowanceVal && allowanceVal.events && allowanceVal.events.Approval && allowanceVal.events.Approval.returnValues && allowanceVal.events.Approval.returnValues.value) ? parseFloat(allowanceVal.events.returnValues.value) : 0;
            console.log(allowanceVal, 'allowanceValallowanceVal')
            _pid == 1 ? setprogress1(false) : setprogress2(false);

            setlpallowance(allowanceVal);
            toastAlert("success", "Approved Successfully", "success");
          } else {
            //token
            const tokenContract = new web3.eth.Contract(
              tokenabi,
              config.tokenContract
            );
            var allowanceVal = await tokenContract.methods
              .approve(config.stakeContract, amt.toString())
              .send({ from: result[0] });
            console.log(allowanceVal, 'allowanceVal')
            allowanceVal = (allowanceVal && allowanceVal.events && allowanceVal.events.Approval && allowanceVal.events.Approval.returnValues && allowanceVal.events.Approval.returnValues.value) ? parseFloat(allowanceVal.events.Approval.returnValues.value) : 0;
            console.log(allowanceVal, 'allowanceValmmmmm')
            _pid == 1 ? setprogress1(false) : setprogress2(false);
            settokenallowance(allowanceVal);
            toastAlert("success", "Approved Successfully", "success");
          }
        } else {
          _pid == 1 ? setprogress1(false) : setprogress2(false);
          localStorage.setItem("account", "");
          history("/Staking");
        }
      } else {
        _pid == 1 ? setprogress1(false) : setprogress2(false);
      }
    } catch (err) {
      console.log(err, 'errerrerr')
      _pid == 1 ? setprogress1(false) : setprogress2(false);
      toastAlert("error", "Process Cancelled", "error");
    }
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
      "0." +
      "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
      lead +
      decimal
      : sign +
      lead +
      (+pow >= decimal.length
        ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
        : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  async function empty() { }

  async function updateoption(opt1, opt2) {
    setstakeamt(0);
    setyouwillpay(0);
    setwithdrawamt(0);
    setoption(opt1);
    setpid(opt2);
  }

  async function getliquidity() {
    try {
      let prov_details = await getConnect();
      if (prov_details) {
        let web3 = new Web3(
          prov_details.provider ? prov_details.provider : window.ethereum
        );
        var network = await web3.eth.net.getId();
        var result = await web3.eth.getAccounts();
        if (network == config.networkVersion && result && result[0]) {


          var lpcontract = new web3.eth.Contract(lpabi, config.lpContract);
          var lpbalance = await lpcontract.methods.balanceOf(config.stakeContract).call();
          lpbalance = (lpbalance > 0) ? (lpbalance / 10 ** 18).toFixed(6) : 0

          var tokencontract = new web3.eth.Contract(lpabi, config.lpContract);
          var tokenbalance = await tokencontract.methods.balanceOf(config.tokenContract).call();

          var stackcontract = new web3.eth.Contract(stakeabi, config.stakeContract);
          var depositedToken = await stackcontract.methods.depositedToken().call();

          var tokenLiquity = parseFloat(tokenbalance) + parseFloat(depositedToken);
          tokenLiquity = (tokenLiquity > 0) ? (tokenLiquity / 10 ** 18).toFixed(6) : 0

          settotalsupply(lpbalance);
          settotalsupply1(tokenLiquity);


        } else {
          setliquitity(0)
        }
      }
    } catch (er) {
    }
  }

  async function stakenow(_pid) {
    try {
      setprogress(true);
      if (stakeamt > 0) {
        let prov_details = await getConnect();
        if (prov_details) {
          let web3 = new Web3(prov_details.provider ? prov_details.provider : window.ethereum)
          var network = await web3.eth.net.getId();
          var result = await web3.eth.getAccounts();
          let amt_stake = youwillpay * 1e18;
          let amt = convert(amt_stake);
          if (network == config.networkVersion && result && result[0]) {
            if (_pid == 0) { //lp
              if (amt <= userlpbalance && parseFloat(bnbbalance) > 0) {
                const lpContract = new web3.eth.Contract(lpabi, config.lpContract);
                const stakeContract = new web3.eth.Contract(stakeabi, config.stakeContract);
                let userallowance = await lpContract.methods.allowance(result[0], config.stakeContract).call();
                let approve = userallowance >= amt ? "" : await lpContract.methods.approve(config.stakeContract, amt.toString()).send({ from: result[0] });
                let rest = await stakeContract.methods.deposit(_pid, amt.toString()).send({ from: result[0] });
                sethash(rest.transactionHash);
                setprogress(false);
                toastAlert("success", "Staked Successfully", "success");
                getdetails();
                window.$("#stake_modal").modal("hide")
                window.$("#alert_modal").modal("show")
                history("/Staking")
              } else {
                setprogress(false);
                let msg = amt > userlpbalance ? "Insufficient Balance" : "Insufficient Balance for Gas Fee";
                toastAlert("error", msg, "error");
              }
            } else {  //token
              if (amt <= usertokenbalance && parseFloat(bnbbalance) > 0) {
                const tokenContract = new web3.eth.Contract(tokenabi, config.tokenContract);
                const stakeContract = new web3.eth.Contract(stakeabi, config.stakeContract);
                let userallowance = await tokenContract.methods.allowance(result[0], config.stakeContract).call();
                let approve = userallowance >= amt ? "" : await tokenContract.methods.approve(config.stakeContract, amt.toString()).send({ from: result[0] });
                let rest = await stakeContract.methods.deposit(_pid, amt.toString()).send({ from: result[0] });
                setprogress(false);
                sethash(rest.transactionHash);
                toastAlert("success", "Staked Successfully", "success");
                getdetails();
                window.$("#stake_modal").modal("hide")
                window.$("#alert_modal").modal("show")
                history("/Staking")
              } else {
                setprogress(false);
                let msg = amt > usertokenbalance ? "Insufficient Balance" : "Insufficient Balance for Gas Fee";
                toastAlert("error", msg, "error");
              }
            }

            getliquidity();
          } else {
            setprogress(false);
            localStorage.setItem("account", "");
            history("/Staking")
          }
        } else {
          setprogress(false);
        }
      } else {
        setprogress(false);
        toastAlert("error", "Enter Valid Amount", "error");
      }
    } catch (err) {
      setprogress(false);
      toastAlert("error", "Process Cancelled", "error");
    }
  }

  async function withdraw(_pid) {
    setprogress(true);
    try {
      if (withdrawamt > 0) {
        let prov_details = await getConnect();
        if (prov_details) {
          let web3 = new Web3(prov_details.provider ? prov_details.provider : window.ethereum)
          var network = await web3.eth.net.getId();
          var result = await web3.eth.getAccounts();
          let amt_stake = withdrawamt * 1e18;
          let amt = convert(amt_stake);
          if (network == config.networkVersion && result && result[0]) {
            if (_pid == 0) { //lp
              if (lpuser.amount >= amt && amt > 0) {
                const stakeContract = new web3.eth.Contract(stakeabi, config.stakeContract);
                let rest = await stakeContract.methods.withdraw(_pid, amt.toString()).send({ from: result[0] });
                setprogress(false);
                sethash(rest.transactionHash);
                toastAlert("success", "Withdraw Successfully Done", "success");
                getdetails();
                window.$("#stake_modal").modal("hide")
                window.$("#alert_modal").modal("show")
                history("/Staking")
              } else {
                setprogress(false);
                let msg = amt < lpuser.amount ? "Insufficient Balance" : "Enter Valid Amount";
                toastAlert("error", msg, "error");
              }
            } else {  //token
              if (tokenuser.amount >= amt && amt > 0) {
                const stakeContract = new web3.eth.Contract(stakeabi, config.stakeContract);
                let rest = await stakeContract.methods.withdraw(_pid, amt.toString()).send({ from: result[0] });
                sethash(rest.transactionHash);
                setprogress(false);
                toastAlert("success", "Withdraw Successfully Done", "success");
                window.$("#stake_modal").modal("hide")
                getdetails();
                window.$("#alert_modal").modal("show")
                history("/Staking")
              } else {
                setprogress(false);
                let msg = amt < tokenuser.amount ? "Insufficient Balance" : "Enter Valid Amount";
                toastAlert("error", msg, "error");
              }
            }
          } else {
            setprogress(false);
            localStorage.setItem("account", "");
            history("/Staking")
          }
        } else {
          setprogress(false);
        }
      } else {
        setprogress(false);
        toastAlert("error", "Enter Valid Amount", "error");
      }
    } catch (err) {
      setprogress(false);
      toastAlert("error", "Process Cancelled", "error");
    }
  }

  async function proceedHarvest(_pid) {
    _pid == 0 ? setlpclaim(true) : settokenclaim(true);
    try {
      let prov_details = await getConnect();
      if (prov_details) {
        let web3 = new Web3(prov_details.provider ? prov_details.provider : window.ethereum)
        var network = await web3.eth.net.getId();
        var result = await web3.eth.getAccounts();
        if (network == config.networkVersion && result && result[0]) {
          if ((_pid == 0 && lpreward > 0) || (_pid == 1 && tokenreward > 0)) {
            const stakeContract = new web3.eth.Contract(stakeabi, config.stakeContract);
            let rest = await stakeContract.methods.claim(_pid).send({ from: result[0] });
            sethash(rest.transactionHash);
            _pid == 0 ? setlpclaim(false) : settokenclaim(false);
            toastAlert("success", "Claimed Successfully", "success");
            getdetails();
            window.$("#alert_modal").modal("show")
            history("/Staking")
          } else {
            _pid == 0 ? setlpclaim(false) : settokenclaim(false);
            toastAlert("error", "Insufficient Reward to Claim", "error");
          }
        } else {
          localStorage.setItem("account", "");
          history("/Staking")
        }
      } else {
        _pid == 0 ? setlpclaim(false) : settokenclaim(false);
      }
    } catch (err) {
      _pid == 0 ? setlpclaim(false) : settokenclaim(false);
      toastAlert("error", "Process Cancelled", "error");
    }
  }

  async function getmax() {
    if (option == "stake") {
      if (pid == 0) {
        let amt = (userlpbalance) / 1e18;
        let _amt = lpinfo ? (amt * ((lpinfo.depositFeeBP) / 100)) / 100 : 0;
        //let totamt = parseFloat(amt) - parseFloat(_amt);
        let totamt = parseFloat(amt);
        setyouwillpay(amt);
        setstakeamt(totamt);
      } else {
        let amt = (usertokenbalance) / 1e18
        let _amt = tokeninfo ? (amt * ((tokeninfo.depositFeeBP) / 100)) / 100 : 0;
        //let totamt = parseFloat(amt) - parseFloat(_amt);
        let totamt = parseFloat(amt);
        setyouwillpay(amt);
        setstakeamt(totamt);
      }
    } else {
      if (pid == 0) {
        let amt = (lpuser.amount) / 1e18
        setwithdrawamt(amt);
      } else {
        let amt = (tokenuser.amount) / 1e18
        setwithdrawamt(amt);
      }
    }
  }

  const inputchange = (e) => {
    if (option == "stake") {
      if (e.target.value != "" && e.target.value >= 0) {
        let amt = pid == 0 ? lpinfo ? (e.target.value * ((lpinfo.depositFeeBP) / 100)) / 100 : 0 : tokeninfo ? (e.target.value * ((tokeninfo.depositFeeBP) / 100)) / 100 : 0;
        let totamt = parseFloat(amt) + parseFloat(e.target.value);
        //setyouwillpay(totamt);
        setyouwillpay(e.target.value);
        setstakeamt(e.target.value);
      } else {
        setyouwillpay(0);
        setstakeamt("");
      }
    } else {
      if (e.target.value >= 0) {
        setwithdrawamt(e.target.value)
      } else {
        setwithdrawamt("");
      }
    }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <Sidebar />
      <div className="buytoken staking">
        <div className="buytokenbg">
          <div className="container">
            <div className="head">
              <h2 className="main_title">Staking</h2>
              <p>
                Participate in PLATINUMSWAP staking, earn PLATINUMSWAP while you
                sleep
              </p>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="box">
                  <div className="head_box">
                    <div className="head_box_flx">
                      <div>
                        <h5>PTSWAP</h5>
                        <p>Earn PLATINUMSWAP</p>
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/platinumswap.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box_ctn">
                    <p className="text-center">Total Value Locked {totalstake ? (parseFloat(totalstake) / 1e18).toFixed(2) : 0} in Both PTSWAP and PTSWAP-BNB</p>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="mb-0">APY</p>
                      <p className="mb-0 text_yellow">{tokenapy}%</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p className="mb-0">Earned</p>
                        <h4 className="text_yellow">{tokenreward}</h4>
                      </div>
                      <button className="primary_btn harvest_btn" type="button" onClick={tokenclaim ? () => empty() : () => proceedHarvest(1)} >
                        {tokenclaim ? "Processing.." : "Harvest"}
                      </button>
                    </div>
                    {/*<div className="count_add_btn d-flex align-items-center justify-content-between mb-2">
                      <label className="mb-2">Start Earning</label>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="mb-0">Unstaked Amount</p>
                      <p className="mb-0 text_yellow">0</p>
                    </div>*/}
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="mb-0">Staked Amount</p>
                      <div>
                        <p className="mb-0 text_yellow">{tokenuser && tokenuser.amount ? parseFloat(tokenuser.amount / 1e18).toFixed(2) : 0}</p>
                      </div>
                    </div>
                    {tokenuser && tokenuser.amount && tokenuser.amount > 0 &&
                      <div className="stakeamount_btn_grps">
                        <button
                          className="primary_btn "
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#stake_modal"
                          onClick={() => updateoption("unstake", 1)}
                        >
                          -
                        </button>
                        <button
                          className="primary_btn "
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#stake_modal"
                          onClick={() => updateoption("stake", 1)}
                        >
                          +
                        </button>
                      </div>
                    }

                    <div className="blw_box">
                      {localStorage.getItem("account") &&
                        localStorage.getItem("account") != null &&
                        localStorage.getItem("account") != undefined &&
                        localStorage.getItem("account") != "" &&
                        tokenuser &&
                        tokenuser.amount == 0 &&
                        tokenallowance == 0 ? (
                        <button
                          className="primary_btn"
                          type="button"
                          onClick={
                            progress1 ? () => empty() : () => tokenapprove(1)
                          }
                        >
                          {progress1 ? "Processing.. Please Wait" : "Approve"}
                        </button>
                      ) : localStorage.getItem("account") &&
                        localStorage.getItem("account") != null &&
                        localStorage.getItem("account") != undefined &&
                        localStorage.getItem("account") != "" &&
                        tokenuser &&
                        tokenuser.amount == 0 &&
                        tokenallowance > 0 ? (
                        <button
                          className="primary_btn"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#stake_modal"
                          onClick={() => updateoption("stake", 1)}
                        >
                          Stake
                        </button>
                      ) : (
                        (!localStorage.getItem("account") ||
                          localStorage.getItem("account") == null ||
                          localStorage.getItem("account") == undefined ||
                          localStorage.getItem("account") == "") && (
                          <button
                            className="primary_btn"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#connect_wallet_modal"
                          >
                            CONNECT WALLET
                          </button>
                        )
                      )}
                    </div>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Details
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <p className="mb-0">Total Liquidity</p>
                              <p className="mb-0 text_yellow">{totalsupply1}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <p className="mb-0">Stake Fee</p>
                              <p className="mb-0 text_yellow">{tokenfee}%</p>
                            </div>
                            <div className="text-end">
                              <a href={config.networkurl + "address/" + config.stakeContract + "#code"} target="_blank">
                                View Contract{" "}
                                <i className="bi bi-box-arrow-up-right ms-1"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="box">
                  <div className="head_box">
                    <div className="head_box_flx">
                      <div>
                        <h5>PTSWAP-BNB LP</h5>
                        <p>Earn PLATINUMSWAP</p>
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/lplogo.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box_ctn">
                    <p className="text-center">Total Value Locked {totalstake ? (parseFloat(totalstake) / 1e18).toFixed(2) : 0} in Both PTSWAP and PTSWAP-BNB</p>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="mb-0">APY</p>
                      <p className="mb-0 text_yellow">{poolapy}%</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p className="mb-0">Earned</p>
                        <h4 className="text_yellow">{lpreward}</h4>
                      </div>
                      <button className="primary_btn harvest_btn" type="button" onClick={lpclaim ? () => empty() : () => proceedHarvest(0)} >
                        {lpclaim ? "Processing.." : "Harvest"}
                      </button>
                    </div>
                    {/*<div className="count_add_btn d-flex align-items-center justify-content-between mb-2">
                      <label className="mb-2">Start Earning</label>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="mb-0">Unstaked Amount</p>
                      <p className="mb-0 text_yellow">0</p>
                    </div>*/}
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="mb-0">Staked Amount</p>
                      <p className="mb-0 text_yellow">{lpuser && lpuser.amount ? parseFloat(lpuser.amount / 1e18).toFixed(2) : 0}</p>
                    </div>
                    {lpuser && lpuser.amount && lpuser.amount > 0 &&
                      <div className="stakeamount_btn_grps">
                        <button
                          className="primary_btn "
                          type="button"
                          onClick={() => updateoption("unstake", 0)}
                          data-bs-toggle="modal"
                          data-bs-target="#stake_modal"
                        >
                          -
                        </button>
                        <button
                          className="primary_btn "
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#stake_modal"
                          onClick={() => updateoption("stake", 0)}
                        >
                          +
                        </button>
                      </div>
                    }
                    <div className="blw_box">
                      {localStorage.getItem("account") &&
                        localStorage.getItem("account") != null &&
                        localStorage.getItem("account") != undefined &&
                        localStorage.getItem("account") != "" && lpuser && lpuser.amount == 0 && lpallowance == 0 ? (
                        <button
                          className="primary_btn"
                          type="button"
                          onClick={progress2 ? () => empty() : () => tokenapprove(0)}
                        >
                          {progress2 ? "Processing.. Please Wait" : "Approve"}
                        </button>
                      ) : localStorage.getItem("account") &&
                        localStorage.getItem("account") != null &&
                        localStorage.getItem("account") != undefined &&
                        localStorage.getItem("account") != "" && lpuser && lpuser.amount == 0 && lpallowance > 0 ?
                        <button
                          className="primary_btn"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#stake_modal"
                          onClick={() => updateoption("stake", 0)}
                        >
                          Stake
                        </button>
                        :
                        (!localStorage.getItem("account") ||
                          localStorage.getItem("account") == null ||
                          localStorage.getItem("account") == undefined ||
                          localStorage.getItem("account") == "") &&
                        <button
                          className="primary_btn"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#connect_wallet_modal"
                        >
                          CONNECT WALLET
                        </button>
                      }
                    </div>
                    <div className="accordion" id="accordionExampleTwo">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Details
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExampleTwo"
                        >
                          <div className="accordion-body">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <p className="mb-0">Total Liquidity</p>
                              <p className="mb-0 text_yellow">{totalsupply}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <p className="mb-0">Stake Fee</p>
                              <p className="mb-0 text_yellow">{poolfee}%</p>
                            </div>


                            <div className="text-end">
                              <a href={config.networkurl + "address/" + config.stakeContract + "#code"} target="_blank">
                                View Contract{" "}
                                <i className="bi bi-box-arrow-up-right ms-1"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="curve"></div>
      <Footer />

      {/* Stake Modal */}
      <div
        className="modal fade primary_modal"
        data-bs-backdrop="static"
        id="stake_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="stake_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{option == "stake" ? "Stake" : "UnStake"}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="buytoken stake_token">
                <div className="box_ctn">
                  <div className="input_panel">
                    <div className="text-end mb-1">
                      {option == "stake" ?
                        <label>
                          Bal: <span>{pid == 0 ? (parseFloat(userlpbalance) / 1e18).toFixed(2) : (parseFloat(usertokenbalance) / 1e18).toFixed(2)}</span>
                        </label>
                        :
                        <label>
                          Staked Amount: <span>{pid == 0 ? (parseFloat(lpuser.amount) / 1e18).toFixed(2) : (parseFloat(tokenuser.amount) / 1e18).toFixed(2)}</span>
                        </label>
                      }
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        min="0"
                        onChange={inputchange}
                        value={option == "stake" ? stakeamt : withdrawamt}
                      />
                      <button className="input-group-text" type="button" onClick={() => getmax()}>Max</button>
                    </div>
                  </div>
                  {option == "stake" &&
                    <label>
                      Stake Fee : <span>{pid == 0 ? lpinfo ? (lpinfo.depositFeeBP) / 100 : 0 : tokeninfo ? (tokeninfo.depositFeeBP) / 100 : 0} %</span>
                    </label>
                  }

                  {option == "stake" &&
                    <div className="text-end mb-1">
                      <label>
                        You will Pay : <span>{youwillpay}</span>
                      </label>
                    </div>
                  }
                </div>
              </div>
              <div className="stake_btn_grps">
                <button
                  className="primary_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button className="primary_btn" type="button" onClick={!progress && option == "stake" ? () => stakenow(pid) : !progress ? () => withdraw(pid) : () => empty()}>{progress ? "Processing.." : "Confirm"}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*alertmodal*/}
      <div
        className="modal fade primary_modal"
        data-bs-backdrop="static"
        id="alert_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="alert_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Transaction Details</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="buytoken stake_token">
                <div className="box_ctn d-flex align-items-center justify-content-center">
                  <label className="mb-0"> Hash :
                  </label>
                  <span className="hash_address">{hash}</span>
                </div>
                <div className="box_ctn text-center">
                  <label>
                    <a href={config.networkurl + "tx/" + hash} target="_blank">
                      View Transaction in BSCScan
                    </a>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WalletModal />
    </div>
  );
}
