import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Link } from "react-router-dom";
import moment from "moment";
import Sidebar from "../components/sidebar.js";
import config from "../config/config";
import { getConnect } from "../action/apicontroller";
import Web3 from "web3";
import { useNavigate } from 'react-router-dom';
import presaleabi from "../ABI/presaleabi";
import tokenabi from "../ABI/tokenabi";
import { toastAlert } from "../helper/toastAlert";
import Sidebarinner from "../components/sidebarinner.js";

import WalletModal from "../components/walletModal";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Buy_Token(props) {
  const [bnbbalance, setbnbbalance] = useState(0);
  const [minimumBuy, setminimumBuy] = useState(0);
  const [bnbprice, setbnbprice] = useState(0);
  const [inputval, setinputval] = useState(0);
  const [outputval, setoutputval] = useState(0);
  const [starttime, setstarttime] = useState(0);
  const [endtime, setendtime] = useState(0);
  const [progress, setprogress] = useState(false);
  const [tokenbalance, settokenbalance] = useState(0);
  const [hash, sethash] = useState("");
  const history = useNavigate();

  useEffect(() => {
    getdetails();
  }, []);

  async function getdetails() {
    let prov_details = await getConnect();
    if (prov_details) {
      let web3 = new Web3(prov_details.provider ? prov_details.provider : window.ethereum)
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (network == config.networkVersion && result && result[0]) {
        localStorage.setItem("account", result[0]);
        let Balance = await web3.eth.getBalance(result[0]);
        Balance = Balance / 1e18;
        setbnbbalance(Balance);
        const tokenContract = new web3.eth.Contract(tokenabi, config.tokenContract);
        let token_balance = await tokenContract.methods.balanceOf(result[0]).call();
        settokenbalance(token_balance);
        const Contract = new web3.eth.Contract(presaleabi, config.persaleContract);
        let _min = await Contract.methods.minBuy().call();
        _min = _min / 1e18;
        setminimumBuy(_min);
        let _price = await Contract.methods.tokenPrice().call();
        _price = parseFloat(_price);
        setbnbprice(_price);
        let _starttime = await Contract.methods.presaleStart().call();
        let _endtime = await Contract.methods.presaleEnd().call();
        console.log(_endtime, '_endtime_endtime')
        setstarttime(_starttime);
        setendtime(_endtime);
      } else {
        setbnbbalance(0);
        localStorage.setItem("account", "");
        history("/buytoken")
      }
    } else {
    }
  }

  async function connect_wallet() {
    let prov_details = await getConnect();
    if (prov_details) {
      let web3 = new Web3(prov_details.provider ? prov_details.provider : window.ethereum)
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (network == config.networkVersion && result && result[0]) {
        localStorage.setItem("account", result[0]);
        history("/buytoken")
      } else {
        localStorage.setItem("account", "");
        history("/buytoken")
      }
    }
  }

  async function getmax() {
    if (bnbbalance && parseFloat(bnbbalance) > 0) {
      setinputval(parseFloat(bnbbalance).toFixed(2));
      let val = parseFloat(bnbprice) * parseFloat(bnbbalance)
      setoutputval(val);
    } else {
      setinputval(0)
    }
  }

  const inputchange = (e) => {
    setinputval(e.target.value);
    if (parseFloat(e.target.value) >= 0 && e.target.value != "") {
      let val = parseFloat(bnbprice) * parseFloat(e.target.value)
      setoutputval(val);
    } else {
      setoutputval(0);
      setinputval("");
    }
  }

  async function buynow() {
    try {
      setprogress(true)
      let prov_details = await getConnect();
      if (prov_details) {
        let web3 = new Web3(prov_details.provider ? prov_details.provider : window.ethereum)
        var network = await web3.eth.net.getId();
        var result = await web3.eth.getAccounts();
        if (network == config.networkVersion && result && result[0]) {
          let date = moment(new Date()).unix();
          console.log(date, 'datedatedate', endtime)
          if (date > starttime) {
            if (date < endtime) {
              if (inputval && inputval != null && inputval != undefined && inputval != "") {
                if (parseFloat(inputval) > 0) {
                  if (parseFloat(bnbbalance) > parseFloat(inputval)) {
                    console.log(parseFloat(inputval), 'inputval minimumBuy', parseFloat(minimumBuy))
                    if (parseFloat(inputval) >= parseFloat(minimumBuy)) {
                      const tokenContract = new web3.eth.Contract(tokenabi, config.tokenContract);
                      const Contract = new web3.eth.Contract(presaleabi, config.persaleContract);
                      let contractBalance = await tokenContract.methods.balanceOf(config.persaleContract).call();
                      contractBalance = contractBalance / 1e18;
                      console.log(contractBalance, 'contractBalancecontractBalance')
                      if (contractBalance >= outputval) {
                        console.log(contractBalance, outputval, inputval)
                        let amt = parseFloat(inputval) * 1e18;
                        amt = await convert(amt);
                        let rest = await Contract.methods.buyTokens().send({ from: result[0], value: amt.toString() });
                        sethash(rest.transactionHash);
                        setprogress(false)
                        toastAlert("success", "Successfully Done", "success");
                        setinputval(0);
                        setoutputval(0);
                        getdetails();
                        window.$("#alert_modal").modal("show");
                        history("/buytoken");
                      } else {
                        setprogress(false)
                        toastAlert("error", "Admin Does not have Enough Balance", "error");
                      }
                    } else {
                      setprogress(false)
                      toastAlert("error", "Check Minimum Buy", "error");
                    }
                  } else {
                    setprogress(false)
                    toastAlert("error", "Insufficient Balance", "error");
                  }
                } else {
                  setprogress(false)
                  toastAlert("error", "Please Enter Valid Amount", "error");
                }
              } else {
                setprogress(false)
                toastAlert("error", "Please Enter Valid Amount", "error");
              }
            } else {
              setprogress(false)
              toastAlert("error", "Presale Ended", "error");
            }
          } else {
            setprogress(false)
            toastAlert("error", "Presale is not yet started", "error");
          }
        } else {
          setprogress(false)
          localStorage.setItem("account", "");
          toastAlert("error", "Please Check network and wallet for connection", "error");
        }
      } else {
        setprogress(false)
        toastAlert("error", "Please Connect Wallet to proceed further", "error");
      }
    } catch (err) {
      console.log(err, "=====err")
      setprogress(false)
      toastAlert("error", "Process Cancelled", "error");
    }
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
      "0." +
      "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
      lead +
      decimal
      : sign +
      lead +
      (+pow >= decimal.length
        ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
        : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  async function empty() {

  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <Sidebarinner />
      <div className="buytoken">
        <div className="buytokenbg">
          <div className="container">
            <div className="head">
              <h2 className="main_title">Buy PLATINUMSWAP Presale</h2>
              <p>
                Participate in PLATINUMSWAP staking, earn PLATINUMSWAP while you
                sleep
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="box">
                  <div className="head_box">
                    <p className="text-end"><span className="text-white">PTSWAP Bal:</span><span>{tokenbalance && parseFloat(tokenbalance) > 0 ? (parseFloat(tokenbalance) / 1e18).toFixed(2) : 0} PTSWAP</span></p>
                    <h5>Current Price</h5>
                    <p>
                      <span>1</span> BNB = <span>{bnbprice ? bnbprice : 0}</span> PLATINUMSWAP
                    </p>
                  </div>
                  <div className="box_ctn">
                    <div className="label">
                      <p>Min: <span>{minimumBuy ? minimumBuy : 0} BNB</span></p>
                      <p>Balance:<span>{bnbbalance && parseFloat(bnbbalance) > 0 ? parseFloat(bnbbalance).toFixed(2) : 0} BNB</span></p>
                    </div>
                    <div className="number_wrapper">
                      <div class="input-group mb-3">
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Enter your amount"
                          value={inputval}
                          onChange={inputchange}
                          min="0"
                        />
                        <button class="input-group-text" type="button" onClick={() => getmax()}>Max</button>
                        {/* <i class="fas fa-sort"></i> */}
                        {/*<div class="input-group-text" onClick={()=>getmax()}>
                       <button class="btn btn-outline-secondary" type="button">
                        Button
                      </button> 
                      Max
                    </div>*/}
                      </div>
                    </div>
                    <div class="blw_box">
                      {localStorage.getItem("account") && localStorage.getItem("account") != null && localStorage.getItem("account") != undefined && localStorage.getItem("account") != "" ?
                        <button class="primary_btn" type="button" onClick={progress ? () => empty() : () => buynow()}>{progress ? "Processing.. Please Wait" : "Buy"}</button>
                        :
                        <button
                          class="primary_btn"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#connect_wallet_modal"
                        >CONNECT WALLET</button>
                      }
                      <p>You will receive <span> {outputval ? parseFloat(outputval).toFixed(2) : 0} PLATINUMSWAP</span></p>
                      {/*<p>Looking for support?<span><Link to="/">Contact</Link></span></p>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="curve"></div>
      <Footer />
      <div
        className="modal fade primary_modal"
        data-bs-backdrop="static"
        id="alert_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="alert_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Transaction Detail</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="buytoken stake_token">
                <div className="box_ctn d-flex align-items-center justify-content-center">
                  <label className="mb-0"> Hash :
                  </label>
                  <span className="hash_address">{hash}</span>
                </div>
                <div className="box_ctn text-center">
                  <label>
                    <a href={config.networkurl + "tx/" + hash} target="_blank">
                      View Transaction in BSCScan
                    </a>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WalletModal />
    </div>
  );
}
