import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import Web3 from "web3";
import WalletConnectProvider from '@walletconnect/web3-provider';
import config from "../config/config";
import { getConnect } from "../action/apicontroller";
import { useNavigate } from 'react-router-dom';
import { toastAlert } from "../helper/toastAlert";

import WalletModal from "../components/walletModal"

export default function Navbar(props) {
  const history = useNavigate();
  const timerRef = useRef(null);
  const [selected, setSelected] = useState("GB");
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });
  }


  async function logout() {
    localStorage.clear()
    window.location.href = "/";
  }

  return (
    <>
      <nav className="navbar navbar-expand bg-light main_navbar">
        {/* <div className="container"> */}
        <NavLink className="navbar-brand" to="/">
          <img
            src={require("../assets/images/platinum/platinumswap_logo.png")}
            className="img-fluid"
            alt="Icon"
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 navbar_right">
            <li class="nav-item">
              <div className="text-center d-flex justfiy-center">
                {localStorage.getItem("account") && localStorage.getItem("account") != null && localStorage.getItem("account") != undefined && localStorage.getItem("account") != "" ?
                  <div class="dropdown">
                    <button
                      className="primary_btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"
                    >{localStorage.getItem("account")}</button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <li><a class="dropdown-item" href="/buytoken">Buy</a></li>
                      <li><a class="dropdown-item" href="/Staking">Stake</a></li>
                      <li><a class="dropdown-item" href="#" onClick={() => logout()}>Log out</a></li>
                    </ul>
                  </div>
                  :
                  <button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >CONNECT WALLET</button>
                }
              </div>
            </li>
          </ul>
          <div className="menu_icon">
            <i class="fas fa-bars"></i>
          </div>
        </div>
        {/* </div> */}
      </nav>

      <WalletModal />

      {/* Connect Wallet Modal */}
      {/* <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button" onClick={()=>connectClick()}>
                      <img
                        src={require("../assets/images/metamask.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>MetaMask</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button" onClick={()=>connectTrustWallet()}>
                      <img
                        src={require("../assets/images/trustwallet.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>Trust Wallet</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
